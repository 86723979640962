import { withLeadingSlash } from 'ufo'
import type { MultilinkStoryblok } from '~/types/storyblok'

export function blokLink(link: MultilinkStoryblok | string) {
  if (typeof link === 'string') {
    return withLeadingSlash(link)
  }

  return link.linktype === 'url' ? link.cached_url : withLeadingSlash(link.cached_url)
}
